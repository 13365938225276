/* You can add global styles to this file, and also import other style files */

body {
    background-color: #ffffff;
}

;
// ########################toast-custom#################
.toast-custom {
    min-width: 320px;
    border-radius: 4px;
    padding: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); 
    cursor: pointer;

}

.toast-error-custom {
    background-color: white !important;
    color: #333 !important; 
    padding: 10px 20px !important; 
    display: flex; 
    align-items: center;
    gap: 15px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1); 
    position: fixed !important;
    top: 10px !important;
    right: 10px !important;
    z-index: 9999 !important;
    border-top: 2px solid red !important;
    border-left: none !important; 
    cursor: pointer;
}

.toast-success-custom {
    background-color: white !important;
    color: #333 !important; 
    padding: 10px 20px !important; 
    display: flex; 
    align-items: center;
    gap: 15px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1); 
    position: fixed !important;
    top: 10px !important;
    right: 10px !important; 
    z-index: 9999 !important;
    border-top: 2px solid green !important; 
    border-left: none !important;
    cursor: pointer;
}

.toast-error-custom .toast-close-button, .toast-success-custom .toast-close-button {
    background-color: transparent !important;
    border: none !important;
    font-size: 20px; // Slightly smaller close button
    color: black !important;
    margin-right: 10px; 
    cursor: pointer;
}

.toast-error-custom .toast-message, .toast-success-custom .toast-message {
    font-weight: bold !important;
    font-size: 14px !important; // Regular font size for readability
}








.sidebar {
    width: 250px;
    background-color: #343a40;
    min-height: 100vh;
    padding: 20px;
    color: #fff;
}

.logo {
    font-size: 24px;
    margin-bottom: 20px;
}

.nav-link {
    color: #212529;
    padding: 8px 0;
}

.nav-link:hover {
    color: #fff;
}

.nav-link.active {
    color: #ffc107;
}

.dropdown-toggle::after {
    float: right;
    margin-top: 8px;
}

.dropdown-menu {
    background-color: #2c3136;
    border: none;
}

.dropdown-item {
    color: #adb5bd;
}

.dropdown-item:hover {
    background-color: #23272b;
    color: #fff;
}

.password-wrapper {
    cursor: pointer;
}
